<template>
  <!-- Not Found -->
  <j-page
    title="Not Found"
    class="not-found"
  >
    <v-col>
      <p class="notice">
        The URL you requested does not match a page we can show you.
      </p>
    </v-col>
  </j-page>
</template>

<script>

export default {
  name: 'NotFound',
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: 'Not Found'
    }
  }
}
</script>

<style lang="scss">

.not-found {
  .notice {
    @extend %j-body-large;
  }
}

</style>